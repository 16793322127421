import React from "react"
// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"

// sections for this page
import CountryEdgeStage from "./Sections/CountryEdgeStage.jsx"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"

import countryEdgeLineupPageStyle from "assets/jss/material-kit-pro-react/views/countryEdgeLineupPageStyle.jsx"

const useStyles = makeStyles(countryEdgeLineupPageStyle)

export default function CountryEdgeLineupPage() {
  const { countryEdgeLineupBackground } = useStaticQuery(graphql`
    query getCountryEdgeLineupBackgroundImages {
      countryEdgeLineupBackground: file(
        relativePath: { eq: "banners/country-edge.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div>
      <SEOHeader
        title="Lineup - Country Edge"
        description="Music lineup and start times for this year's Country Edge"
      />
      <CustomHeader />
      <ReactParallax
        imageData={countryEdgeLineupBackground.childImageSharp.gatsbyImageData}
        imageTitle={"Country Edge Lineup Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem md={8} sm={8} className={classes.scholarshipsItem}>
              <h1 className={classes.pageTitle}>Country Edge Live Music</h1>
              <h3 className={classes.message}>Lineup and Schedule</h3>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <CountryEdgeStage />
        <SectionTimer />
        <CustomFooter />
      </div>
    </div>
  )
}
