import { faUserClock } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import stage from "assets/img/stage.svg"
import starsPattern from "assets/img/stars-pattern.png"
// page styles
import countryEdgeStageStyle from "assets/jss/material-kit-pro-react/views/countryEdgeLineupPageSections/countryEdgeStageStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import React from "react"
// react component for creating beautiful carousel
import Carousel from "react-slick"

const useStyles = makeStyles(countryEdgeStageStyle)

export default function CountryEdgeStage() {
  const { wjvlLogo, lineupData } = useStaticQuery(graphql`
    query getCountryEdgeStageImages {
      wjvlLogo: file(relativePath: { eq: "logos/999-wjvl-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      lineupData: allCalendarEvent(filter: { colorId: { eq: "5" } }) {
        edges {
          node {
            summary
            description
            attachments {
              fileId
            }
            end {
              dateTime
            }
            start {
              dateTime
            }
          }
        }
      }
    }
  `)
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  }
  const classes = useStyles()
  return (
    <div
      className={classes.section}
      style={{ backgroundImage: `url(${starsPattern})` }}
      id="carousel"
    >
      <div className={classes.container}>
        <GridContainer className={classes.centerItems}>
          <img
            className={classes.stageImage}
            src={stage}
            alt="Country Edge Music Lineup Stage"
          />
          <h1 className={classes.lineupHeader}>Friday Music Lineup</h1>
          <GridItem xs={12} sm={10} md={8} className={classes.marginAuto}>
            <Carousel {...settings} className={classes.lineupCarousel}>
              {lineupData &&
                lineupData.edges.length > 0 &&
                lineupData.edges.map((band, key) => {
                  return (
                    <div key={key}>
                      {band.node.attachments &&
                        band.node.attachments.length > 0 && (
                          <img
                            src={`https://drive.google.com/thumbnail?id=${band.node.attachments[0].fileId}`}
                            className="slick-image"
                            alt="Chilimania Stage"
                          />
                        )}

                      <div className="slick-caption">
                        <h2 className={classes.bandName}>
                          {band.node.summary}
                        </h2>
                        <div className={classes.lineupStartTime}>
                          <FontAwesomeIcon icon={faUserClock} />
                          <b>
                            {moment(band.node.start.dateTime).format("ddd, hA")}
                          </b>
                        </div>
                        <div
                          className={classes.lineupDescription}
                          dangerouslySetInnerHTML={{
                            __html: band.node.description,
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
            </Carousel>
            <div className={classes.lineupSponsorWrapper}>
              <GatsbyImage
                image={wjvlLogo.childImageSharp.gatsbyImageData}
                alt="99.9 WJVL Logo"
                objectFit={"contain"}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
