import {
  container,
  main,
  mlAuto,
  mrAuto,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const countryEdgeLineupPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: 1,
  },
  main: {
    ...main,
    position: "relative",
    background: "#f5f5f5",
  },
  message: {
    color: whiteColor,
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "1.15rem",
    },
    "@media (max-height: 374px)": {
      marginTop: "0px",
    },
    "@media (max-width: 374px)": {
      fontSize: "1.25rem",
    },
  },
  pageTitle: {
    ...title,
    ...textCenter,
    color: whiteColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    "@media (max-width: 374px)": {
      fontSize: "2.75rem",
    },
  },
  scholarshipsItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
  },
  textCenter,
})

export default countryEdgeLineupPageStyle
